import React, { useState, Fragment, useMemo, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { parse, HTMLElement } from 'node-html-parser'
import { isTabletScreen } from '@lib/utils/mediaQuery'
import { useRouter } from 'next/router'
import s from '@commonStyles/menus/MenuBlock.module.scss'
import cn from 'classnames'
import { Button } from '@corratech/pylot-ui'
import { useStoreConfig } from '@pylot/config'
export const topCategoryIndex = 0

const SubCategoryList = dynamic(() =>
    import('./SubCategoryList/SubCategoryList').then(
        (mod) => mod.SubCategoryList
    )
)
const SecondLevelCategoryList = dynamic(() =>
    import('./SecondLevelCategoryList/SecondLevelCategoryList').then(
        (mod) => mod.SecondLevelCategoryList
    )
)
const TopCategoryList = dynamic(() =>
    import('./TopCategoryList/TopCategoryList').then(
        (mod) => mod.TopCategoryList
    )
)
const MobileLogo = dynamic(() => import('@components/icons/MobileLogo'))
const Cross = dynamic(() => import('@components/icons/Cross'))
const RichContent = dynamic(() =>
    import('@corratech/pylot-rich-content').then((mod) => mod.RichContent)
)
const Menu = dynamic(() => import('@components/icons/Menu'))

interface MenuBlockProps {
    menu: string
    isDesktopMenu: boolean
    contactUsContent?: string
}
export const MenuBlock: React.FC<MenuBlockProps> = (props) => {
    const [t] = useTranslation()
    const { menu, isDesktopMenu, contactUsContent } = props
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1)
    const [menuLevel, setMenuLevel] = useState<number>(1)
    const [activeTopMenu, setActiveTopMenu] = useState<number>(0)
    const [toggle, setToggle] = useState<boolean>(false)
    const [activeTopMenuText, setActiveTopMenuText] = useState<string>('')
    const [activeSubMenuText, setActiveSubMenuText] = useState<string>('')
    const [subCategoryDOM, setSubCategoryDOM] = useState<HTMLElement[] | null>(
        null
    )
    const {
        base: {
            url: { baseUrl = '/' }
        }
    } = useStoreConfig()
    const isTab = isTabletScreen()
    const router = useRouter()

    const generateItems = (data: string, selector: string) => {
        const menuContent: HTMLElement = parse(data)
        const parseData: HTMLElement[] = [
            ...menuContent?.querySelectorAll(selector)
        ]!
        return parseData
    }
    const getItems = (data: HTMLElement, selector: string) => {
        return !isDesktopMenu
            ? [...data.querySelectorAll('li:not(.third-level-item)')]
            : [...data.querySelectorAll(selector)]
    }
    const mainItemSelector = !isDesktopMenu
        ? 'div[class=list-heading]'
        : 'span[class=tab-title]'
    const mainItem: HTMLElement[] = menu
        ? generateItems(menu, mainItemSelector)
        : []
    const contentItemSelector = !isDesktopMenu
        ? 'ul[class=list-content]'
        : '[data-content-type=tab-item]'
    const contentItem = generateItems(menu, contentItemSelector)
    const bannerItemSelector = !isDesktopMenu ? '.banner-column' : ''
    const bannerItem = generateItems(menu, bannerItemSelector)
    const currentBannerMenuContent = bannerItem[activeTopMenu]
    const currentMenuContent = contentItem[activeTopMenu]
    const initialMenuActiveTopTextContent = (
        mainItem?.[topCategoryIndex]?.querySelectorAll(
            '.btn-link'
        )?.[0] as HTMLElement
    )?.innerHTML
    const style = generateItems(menu, 'style')
    const toHTML = (str: string) => ({ __html: str })
    const toggleHover = (index: number) => {
        setHoveredIndex(index)
    }

    const handleResize = () => {
        const html = document.getElementsByTagName('html')[0]
        const isTab = isTabletScreen()
        if (!isTab) {
            html.classList.remove('megamenu-open')
            setToggle(false)
        }
    }

    useEffect(() => {
        if (isTab) {
            const html = document.getElementsByTagName('html')[0]
            if (toggle) {
                html.classList.add('megamenu-open')
            } else {
                html.classList.remove('megamenu-open')
            }
        }
    }, [toggle])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        router.events.on('routeChangeStart', resetMenu)

        return () => {
            router.events.off('routeChangeStart', resetMenu)
        }
    }, [])

    const resetMenu = () => {
        if (isTab) {
            const html = document.getElementsByTagName('html')[0]
            html.classList.remove('megamenu-open')
            setToggle(false)
        } else {
            toggleHover(-1)
        }
    }

    const menuElements = useMemo(
        () =>
            mainItem?.map((value, index) => {
                const menuHeader = value.innerHTML
                const menuContent = contentItem[index]
                menuContent?.setAttribute('data-content-type', 'row')
                const topUrl = !isDesktopMenu
                    ? value
                          .querySelectorAll('.btn-link')[0]
                          .getAttribute('href')
                    : menuContent?.getAttribute('data-tab-url')
                let externalUrl = false
                if (
                    topUrl &&
                    (topUrl.includes('https://') || topUrl.includes('http://'))
                ) {
                    externalUrl = true
                }
                return (
                    <Fragment key={`${value.range?.[0] ?? index}`}>
                        <li
                            className={`menu-item level-1 ${
                                hoveredIndex === index ? 'active' : ''
                            } `}
                            onMouseEnter={() => toggleHover(index)}
                            onMouseLeave={() => toggleHover(-1)}
                        >
                            <div className={cn(s['menu-top'])}>
                                {!externalUrl ? (
                                    <Link
                                        className={cn(s['levelTop'])}
                                        href={topUrl ? `${topUrl}` : '#'}
                                        dangerouslySetInnerHTML={toHTML(
                                            t(menuHeader)
                                        )}
                                        onMouseEnter={() =>
                                            setActiveTopMenuText(menuHeader)
                                        }
                                    />
                                ) : (
                                    <Link
                                        className={cn(s['levelTop'])}
                                        href={topUrl ?? '#'}
                                    >
                                        {t(menuHeader)}
                                    </Link>
                                )}
                            </div>
                            <div className="subCat level-2">
                                <div className="container-width">
                                    <RichContent
                                        html={menuContent?.outerHTML.concat(
                                            `${
                                                style.length > 0
                                                    ? style[0].outerHTML
                                                    : ''
                                            }`
                                        )}
                                    />
                                </div>
                            </div>
                        </li>
                    </Fragment>
                )
            }),
        [mainItem]
    )

    const renderDesktopMenu = () => {
        return (
            <div className="menu-container">
                <ul className="MenuWrapper">{menuElements}</ul>
            </div>
        )
    }

    const renderMobileMenu = () => {
        let renderedComponent = null
        if (menuLevel === 1 && toggle) {
            renderedComponent = (
                <TopCategoryList
                    menuItem={mainItem}
                    setMenuLevel={setMenuLevel}
                    setActiveTopMenu={setActiveTopMenu}
                    setActiveTopMenuText={setActiveTopMenuText}
                    contentItem={contentItem}
                    setToggle={setToggle}
                    isDesktopMenu={isDesktopMenu}
                    initialMenuLoad
                    contactUsContent={contactUsContent}
                    initialMenuLoadContent={
                        <SecondLevelCategoryList
                            secondLevelMenuItems={getItems(
                                contentItem[topCategoryIndex],
                                'menu-wrapper'
                            )}
                            setMenuLevel={setMenuLevel}
                            activeTopMenuText={activeTopMenuText}
                            setActiveSubMenuText={setActiveSubMenuText}
                            setSubCategoryDOM={setSubCategoryDOM}
                            setToggle={setToggle}
                            menuLevel={menuLevel}
                            isSecondaryComingFromTop
                            setActiveTopMenu={setActiveTopMenu}
                            setActiveTopMenuText={setActiveTopMenuText}
                            initialMenuActiveTopText={
                                initialMenuActiveTopTextContent
                            }
                        />
                    }
                />
            )
        } else if (menuLevel === 2 && toggle) {
            renderedComponent = (
                <SecondLevelCategoryList
                    secondLevelMenuItems={getItems(
                        currentMenuContent,
                        'menu-wrapper'
                    )}
                    setMenuLevel={setMenuLevel}
                    activeTopMenuText={activeTopMenuText}
                    setActiveSubMenuText={setActiveSubMenuText}
                    setSubCategoryDOM={setSubCategoryDOM}
                    setToggle={setToggle}
                    subMenuPromoBannerSection={[currentBannerMenuContent]}
                    bannerStyle={style}
                    isSecondaryComingFromTop={false}
                    setActiveTopMenu={setActiveTopMenu}
                    setActiveTopMenuText={setActiveTopMenuText}
                />
            )
        } else if (menuLevel === 3 && toggle) {
            renderedComponent = (
                <SubCategoryList
                    subCategoryContent={subCategoryDOM}
                    setMenuLevel={setMenuLevel}
                    activeSubMenuText={activeSubMenuText}
                    setToggle={setToggle}
                    subMenuPromoBannerSection={[currentBannerMenuContent]}
                    bannerStyle={style}
                />
            )
        }
        return renderedComponent || ''
    }

    return (
        <nav className={cn(s['static-megamenu'], toggle ? s['open'] : '')}>
            {isDesktopMenu ? (
                renderDesktopMenu()
            ) : (
                <>
                    <button
                        aria-label={t('menu')}
                        aria-expanded={toggle ? 'true' : 'false'}
                        type="button"
                        onClick={(event) => {
                            event.stopPropagation()
                            setToggle(!toggle)
                            setMenuLevel(1)
                        }}
                        className="hamburger-icon lg:hidden"
                    >
                        <Menu />
                    </button>
                    <div
                        className={cn(
                            s['menu-main-wrapper'],
                            'menu-main-wrapper'
                        )}
                    >
                        <div className={cn(s['megamenu-header-mobile'])}>
                            <div
                                aria-hidden
                                className={s['megamenu-header-logo']}
                                onClick={() => setToggle(!toggle)}
                            >
                                <Link href={baseUrl}>
                                    <MobileLogo />
                                </Link>
                            </div>
                            <Button
                                variant="link"
                                type="button"
                                className={s['close-btn']}
                                onClick={() => setToggle(!toggle)}
                                aria-label={t('Close Mobile Menu')}
                            >
                                <Cross />
                            </Button>
                        </div>
                        {renderMobileMenu()}
                    </div>
                </>
            )}
        </nav>
    )
}
